.iterationHeading {
  display: flex;
  align-items: center;

  .iterationSpacer {
    display: flex;
    align-items: center;
    flex-grow: 1;
    flex-basis: 0;

    &:first-child {
      justify-content: flex-start;
    }

    &:last-child {
      justify-content: flex-end;
    }

    .iterationArrow {
      font-size: 0.5em;
      text-decoration: none;
      padding: 0.25em 0.6em;
    }
  }
}
