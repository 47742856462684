.primaryButton {
  display: inline-block;
  color: white;
  background: var(--blue-50);
  padding: 5px 8px;
  border: 0;
  border-radius: 3px;
  line-height: 1;
  font-family: inherit;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none !important;
  cursor: default;

  &:hover {
    color: white;
    background: var(--blue-40);
  }
}

.plainLink {
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.headerButton {
  font-size: 14px;
  vertical-align: middle;
}

.smallInput {
  padding: 5px 8px;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  background-color: #fff;
}

.codeSnippet {
  background: var(--grey-10);
  padding: 8px;
  font-family: var(--monospace-font);
  border: 1px solid rgba(0, 0, 0, 0.1);
}
