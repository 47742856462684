const _temp0 = require("../../img/icons/001-calendar.svg");
const _temp1 = require("../../img/icons/002-presentation.svg");
const _temp2 = require("../../img/icons/003-user.svg");
const _temp3 = require("../../img/icons/004-inbox.svg");
const _temp4 = require("../../img/icons/alert.svg");
const _temp5 = require("../../img/icons/arrow-d-dark.svg");
const _temp6 = require("../../img/icons/arrow-l-dark.svg");
const _temp7 = require("../../img/icons/arrow-r-dark.svg");
const _temp8 = require("../../img/icons/balloons.svg");
const _temp9 = require("../../img/icons/calendar2.svg");
const _temp10 = require("../../img/icons/capacity.svg");
const _temp11 = require("../../img/icons/copy.svg");
const _temp12 = require("../../img/icons/defect.svg");
const _temp13 = require("../../img/icons/enhancement.svg");
const _temp14 = require("../../img/icons/hourglass.svg");
const _temp15 = require("../../img/icons/info.svg");
const _temp16 = require("../../img/icons/jira.svg");
const _temp17 = require("../../img/icons/logo.svg");
const _temp18 = require("../../img/icons/overflow.svg");
const _temp19 = require("../../img/icons/pocket.svg");
const _temp20 = require("../../img/icons/refresh.svg");
const _temp21 = require("../../img/icons/report.svg");
const _temp22 = require("../../img/icons/rgb.svg");
const _temp23 = require("../../img/icons/settings-circle.svg");
const _temp24 = require("../../img/icons/settings.svg");
const _temp25 = require("../../img/icons/stethoscope.svg");
const _temp26 = require("../../img/icons/task.svg");
const _temp27 = require("../../img/icons/up-arrow-yellow.svg");
const _temp28 = require("../../img/icons/up-arrow.svg");
const _temp29 = require("../../img/icons/warning.svg");
module.exports = {
  "001-calendar": _temp0,
  "002-presentation": _temp1,
  "003-user": _temp2,
  "004-inbox": _temp3,
  "alert": _temp4,
  "arrow-d-dark": _temp5,
  "arrow-l-dark": _temp6,
  "arrow-r-dark": _temp7,
  "balloons": _temp8,
  "calendar2": _temp9,
  "capacity": _temp10,
  "copy": _temp11,
  "defect": _temp12,
  "enhancement": _temp13,
  "hourglass": _temp14,
  "info": _temp15,
  "jira": _temp16,
  "logo": _temp17,
  "overflow": _temp18,
  "pocket": _temp19,
  "refresh": _temp20,
  "report": _temp21,
  "rgb": _temp22,
  "settings-circle": _temp23,
  "settings": _temp24,
  "stethoscope": _temp25,
  "task": _temp26,
  "up-arrow-yellow": _temp27,
  "up-arrow": _temp28,
  "warning": _temp29
}