.containerOpen,
.containerClosed {
  width: 250px;
  line-height: 1.4;
  @media(prefers-reduced-motion: no-preference) {
    transition: margin 0.25s ease;
  }
  @media(max-width: 1210px) {
    display: none;
  }
}

.containerClosed {
  @media(prefers-reduced-motion: no-preference) {
    margin-right: -250px;
    transition: margin 0.25s ease, visibility 0s ease 0.25s;
    visibility: collapse;
  }
  @media(prefers-reduced-motion: reduce) {
    display: none;
  }
}

.p1, .p2, .p3, .p4, .p5,
.s1, .s2, .s3, .s4, .s5,
.sm, .md, .lg, .xl, .total,
.n\/a,
.legendButton {
  border: 1px solid;
  padding: 0 3px;
  border-radius: 3px;
  font-weight: 800;

  &:where(button) {
    cursor: pointer;
  }
}

.p1 {
  background: #0083ff;
  border-color: transparent;
  color: white;
}
.p2 {
  background: #ac6bc3;
  border-color: transparent;
  color: white;
}
.p3 {
  color: #bf6fd4;
  background: transparent;
}
.p4, .p5 {
  color: #7e7e7e;
  background: transparent;
}
.s1 {
  background: #ff4d4d;
  border-color: transparent;
  color: white;
}
.s2 {
  background: #ff9d4d;
  border-color: transparent;
  color: white;
}
.s3 {
  color: #ff4d4d;
  background: transparent;
}
.s4, .s5 {
  color: #7e7e7e;
  background: transparent;
}
.sm {
  color: #45a1ff;
  background: transparent;
}
.md {
  background: #79de54;
  border-color: transparent;
  color: white;
}
.lg {
  background: #ff9d4d;
  border-color: transparent;
  color: white;
}
.xl {
  background: #ff4d4d;
  border-color: transparent;
  color: white;
}
.total {
  color: inherit;
  background: transparent;
}
.n\/a {
  border-color: #7e7e7e;
  color: #7e7e7e;
}
.-- {
  background: none;
  border: none;
}
.legendButton {
  background: #45a1ff;
  border-color: transparent;
  color: white;
}

.sidebarToggle {
  position: absolute;
  padding-top: 40px;
  right: 10px;
  border: 0;
  background: none;
  height: 20px;
  cursor: pointer;

  @media(max-width: 1210px) {
    display: none;
  }
}

.innerBox {
  background: #f9f9fa;
  padding-block: 20px;
  position: absolute;
  width: 250px;
  top: 0;
  bottom: 0;
  overflow: hidden;
}

.title {
  margin-inline: 20px;
}

.scrollbox {
  max-height: 100%;
  padding: 0 20px 50px 20px;
  overflow: auto;
}

.iconArrowhead {
  display: inline-block;
  height: 16px;
  width: 16px;
  vertical-align: middle;
  margin-right: 5px;
  background-image: url("../../img/icons/arrow-r-dark.svg");
}

.iconOverflow {
  display: inline-block;
  height: 16px;
  width: 16px;
  vertical-align: middle;
  margin-right: 5px;
  background-image: url("../../img/icons/arrow-l-dark.svg");
}

