.loader {
  --loader-color: var(--grey-30);
  color: var(--loader-color);
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  transform: translateZ(0);
  animation-delay: -0.16s;
  &,
  &::before,
  &::after {
    background: var(--loader-color);
    animation: 1s ease-in-out infinite load1;
    width: 1em;
    height: 4em;
  }
  &::before,
  &::after {
    position: absolute;
    top: 0;
    content: '';
  }
  &::before {
    left: -1.5em;
    animation-delay: -0.32s;
  }
  &::after {
    left: 1.5em;
  }
}
.loaderContainer {
  animation: 1s fadein1;
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes fadein1 {
  from {
    opacity: 0;
  }
  to {
  }
}
