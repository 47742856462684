.detailsHeading {
  margin-block: 38px 13px;

  details {
    margin-inline: 4px;

    summary {
      display: flex;
      cursor: pointer;

      .summaryLabel::before {
        content: "▶︎\00a0\00a0";
      }

      .spacer {
        flex-grow: 1;
      }

      & > input[type="checkbox"] {
        margin-block: 0;
      }
    }

    &[open] .summaryLabel::before {
      content: "▼\00a0\00a0";
    }
  }
}
