@import "../../styles/colors.module";

.bugTable {
  + .bugTable {
    margin-top: 20px;
  }
  width: 100%;
  border-collapse: separate;
  overflow: hidden;
  border-spacing: 0;
  border-radius: 3px;
  border: 1px solid $lightborder;

  :where(thead, tbody, tfoot) > tr:not(:last-child) > :where(th, td),
  tr:not(:last-child) > :where(th, td),
  :where(thead, tbody, tfoot):not(:last-child)
    > tr:last-child
    > :where(th, td) {
    border-bottom: 1px solid $lightborder;
  }
}

.th {
  white-space: nowrap;
  font-weight: normal;
  text-align: left;
  padding: 10px;
}

.labels {
  .th {
    padding: 6px 10px;
    color: var(--grey-50);
    background: var(--grey-10);
    font-size: 12px;
    font-weight: 600;
  }
}
.editor {
  .th {
    padding: 8px 10px;
    background: var(--grey-10);
  }
}
.editorCompact {
  .th {
    padding: 6px 10px;
    background: var(--grey-10);
  }
}

.td {
  vertical-align: middle;
  padding: 10px;
  a {
    text-decoration: none;
    color: var(--blue-50);
    &:hover {
      text-decoration: underline;
    }
  }
  &.summaryColumn {
    font-size: 14px;
    word-wrap: anywhere;
    a {
      color: inherit;
    }
  }
  &.idColumn {
    font-family: var(--monospace-font);
    font-size: 14px;
    width: 1%;
    white-space: nowrap;
    font-weight: bold;
    .idSpacer {
      user-select: none;
      font-size: 0.5em;
    }
    .warning {
      color: hsl(30, 75%, 52%);
      text-decoration: underline wavy 0.05em;
      &:hover {
        text-decoration: none;
      }
    }
  }
  &.last_change_timeColumn {
    white-space: nowrap;
  }
  & > img {
    display: block;
  }
}

.resolved {
  color: rgba(0, 0, 0, 0.5);
  a {
    color: inherit;
    text-decoration: line-through;
  }
  .tagList li {
    opacity: 0.5;
  }
}

.mine {
  background-color: hsla(213, 100%, 39%, 0.1);
}

.tagList {
  list-style: none;
  padding: 0;
  display: inline;
  margin-left: 5px;
  li {
    font-size: 11px;
    color: var(--blue-40);
    border: 1px solid;
    border-radius: 3px;
    padding: 2px 5px;
    display: inline-block;
    font-family: var(--monospace-font);
    & + li {
      margin-left: 3px;
    }
  }
}

.bulkColumn {
  width: 1%;
}

.editorGroup {
  display: inline;
  & + .editorGroup {
    margin-left: 20px;
  }
}

.editorType {
  display: flex;
  align-items: center;
  height: 20px;
}

.leftEditorGroup {
  flex-grow: 1;
}

.emptyState {
  text-align: center;
  font-size: 14px;
  background: var(--grey-20);
  padding: 10px;
  border-radius: 3px;
}

.statusLabel {
  font-size: 11px;
  font-weight: bold;
  color: white;
  background: var(--blue-40);
  border-radius: 3px;
  padding: 2px 5px;
  display: inline-block;
  font-family: var(--monospace-font);
  &.status-verified {
    color: white;
    border: 0;
    background-color: #00b58f;
  }
}
