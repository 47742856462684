.RSTable {
  padding: 0.6em;
  margin: 0 auto;
  width: max-content;

  td {
    padding: 0.6em;
  }

  tbody {
    font-family: var(--monospace-font);

    tr {
      border-bottom: 1px solid #ddd;

      &:hover {
        background: #ddd;
      }
    }
  }

  thead {
    font-size: 1.1em;
    font-weight: bold;
  }
}
