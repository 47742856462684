.aside {
  padding: 12px;
  flex-shrink: 0;
  width: 320px;
  overflow: auto;
  background: #f9f9fa;
  font-size: 14px;
  font-weight: 500;
  border-right: 1px solid rgba(0, 0, 0, 0.02);
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
}

.navLink {
  color: inherit;
  text-decoration: none;
  border-radius: 3px;
  display: flex;
  align-items: center;
  > span {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &.active,
  &:hover {
    background-color: #ededf0;
  }
  &:hover {
    color: #0a84ff;
  }
  padding: 3px;
}

.header {
  color: #777c;
  font-weight: 600;
  padding: 3px;
}

.spacer {
  padding: 8px;
}

.main {
  position: relative;
  flex-grow: 1;
  overflow: auto;
}

.logo {
  font-family: "Zilla Slab", "Nunito Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Ubuntu", "Helvetica Neue", sans-serif;
  font-size: 24px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0.5px;
  text-decoration: none !important;
  color: #333 !important;
  background: none !important;
  border-radius: 0;
  width: min-content;
  margin-bottom: 8px;
  padding-block: 3px 5px;
  padding-inline-end: 14px;
  position: relative;

  &:hover {
    color: #0a84ff !important;
  }

  .icon {
    width: 1em;
    height: 1em;
    margin-inline-end: 6px;
  }
}

.icon {
  display: inline-block;
  height: 20px;
  width: 20px;
  vertical-align: middle;
  margin-right: 5px;
}
.icon-logo {
  background-image: url("../../img/icons/logo.svg");
  background-size: contain;
}
.icon-calendar {
  background-image: url("../../img/icons/001-calendar.svg");
}
.icon-calendar2 {
  background-image: url("../../img/icons/calendar2.svg");
}
.icon-graph {
  background-image: url("../../img/icons/002-presentation.svg");
}
.icon-user {
  background-image: url("../../img/icons/003-user.svg");
}
.icon-inbox {
  background-image: url("../../img/icons/004-inbox.svg");
}
.icon-rgb {
  background-image: url("../../img/icons/rgb.svg");
}
.icon-alert {
  background-image: url("../../img/icons/alert.svg");
}
.icon-report {
  background-image: url("../../img/icons/report.svg");
}
.icon-info {
  background-image: url("../../img/icons/info.svg");
}
.icon-refresh {
  background-image: url("../../img/icons/refresh.svg");
}
.icon-warning {
  background-image: url("../../img/icons/warning.svg");
}
.icon-balloons {
  background-image: url("../../img/icons/balloons.svg");
}
.icon-up-arrow {
  background-image: url("../../img/icons/up-arrow.svg");
}
.icon-up-arrow-yellow {
  background-image: url("../../img/icons/up-arrow-yellow.svg");
}
.icon-pocket {
  background-image: url("../../img/icons/pocket.svg");
}
.icon-hourglass {
  background-image: url("../../img/icons/hourglass.svg");
}
.icon-settings {
  background-image: url("../../img/icons/settings.svg");
}
.icon-settings-circle {
  background-image: url("../../img/icons/settings-circle.svg");
}
.icon-stethoscope {
  background-image: url("../../img/icons/stethoscope.svg");
  background-size: contain;
}
.icon-capacity {
  background-image: url("../../img/icons/capacity.svg");
  background-size: contain;
}
.icon-jira {
  background-image: url("../../img/icons/jira.svg");
  background-size: contain;
}
