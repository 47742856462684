@import "../../styles/colors.module";

.container {
  max-width: 700px;
  margin: 0 auto;
  padding: 20px 40px;
}

.list {
  width: 100%;
  border-collapse: separate;
  overflow: hidden;
  border-spacing: 0;
  border-radius: 3px;
  border: 1px solid $lightborder;

  .row {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 10px;

    &:not(:last-child) {
      border-bottom: 1px solid $lightborder;
    }
  }

  .col {
    &:first-child {
      white-space: nowrap;
      flex-grow: 1;
    }
  }
}
