.container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 20px 40px;
}
.headerButton {
  font-size: 14px;
  vertical-align: middle;
}
.subheading {
  text-align: center;
  font-size: 14px;
}

.featureTable {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 14px;

  .labels {
    vertical-align: bottom;

    .idColumn {
      padding-right: 20px;
      align-self: flex-end;
    }
    .idColumn,
    .displayNameColumn {
      font-weight: 600;
      font-size: 18px;
    }
  }

  th {
    white-space: nowrap;
    font-weight: normal;
    text-align: left;
    padding: 2px;
    padding-bottom: 6px;
    padding-top: 12px;
  }

  td {
    vertical-align: baseline;
    padding: 2px;
    font-weight: 500;

    a {
      text-decoration: none;
      color: inherit;
      &:hover {
        text-decoration: underline;
      }
    }
    &.idColumn {
      font-family: var(--monospace-font);
      white-space: nowrap;
    }
  }

  .resolved {
    color: rgba(0, 0, 0, 0.3);
    font-weight: 400;

    a {
      color: inherit;
      text-decoration: line-through;
      &:hover {
        text-decoration: line-through underline;
      }
    }
  }
}

.emptyState {
  text-align: center;
  font-size: 14px;
  background: var(--grey-20);
  padding: 10px;
  border-radius: 3px;
}
