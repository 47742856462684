.container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 20px 40px;
}

.heading {
  margin-bottom: 20px;
}

.subHeading {
  text-align: center;
}
