.container {
  max-width: 1600px;
  margin: 0 auto;
  padding: 20px 40px;
}
.wrapper {
  display: flex;
}
.mainColumn {
  flex: 3;
  margin-right: 20px;
}
.sideColumn {
  flex: 2;
}
.settingsBtn {
  height: 16px;
  width: 16px;
  background: transparent url("../../img/icons/settings.svg");
  border: 0;
  position: relative;
  top: -19px;

  &:hover {
    cursor: pointer;
  }
}
.settingSection {
  text-align: center;
}
