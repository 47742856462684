.container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 20px 40px;
}

.summary,
.featureSummary {
  font-size: 16px;
  line-height: 1.4;
}

.feature {
  padding: 20px 0;
}

.bugList {
  font-size: 14px;
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    margin-bottom: 5px;
  }
  a {
    color: inherit;
    text-decoration: none;
    &:hover {
      color: var(--blue-50);
    }
  }
}
.resolved {
  opacity: 0.5;
  &::before {
    content: "✓ "
  }
}

.h3 {
  border-top: 1px solid rgba(0,0,0,0.2);
}

.prevUrlButton {
  float: left;
}

.nextUrlButton {
  float: right;
}

.table table {
  width: -moz-available;
  border: 1px solid black;
  border-spacing: 0;
  border-collapse: collapse;

  th, td {
    border: 1px solid black;
    padding:4px 6px;
  }

  th {
    vertical-align: bottom;
  }

  thead > tr {
    background-color: rgb(228, 240, 245);
  }

  thead > tr:nth-of-type(2) {
    border-bottom: 2px solid black;
  }

  thead > tr:last-of-type > th:nth-of-type(1) {
    background-color: rgb(225, 255, 225);
  }

  thead > tr:last-of-type > th:nth-of-type(2) {
    background-color: rgb(255, 225, 225);
  }

  tbody > tr:nth-of-type(even) {
    background-color: rgb(237, 238, 242);
  }

  tbody > tr > th:first-of-type {
    text-align: left;
    background-color: rgb(225, 229, 244);
  }

  tbody > tr > td:last-of-type {
    text-align:right;
  }
}
