@import "../../../styles/colors.module";

.tabsContainer {
  &.noTopPadding {
    padding-top: 0;
  }

  padding-top: 30px;
  margin-bottom: 20px;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;

    li {
      a {
        position: relative;
        text-decoration: none;
        background: #fff;
        display: block;
        font-weight: 600;
        padding: 10px 14px;
        border: 1px solid transparent;
        border-bottom-color: $lightborder;
        color: #949494;

        &.activeTab {
          border-color: $lightborder;
          border-radius: 3px 3px 0 0;
          border-bottom-color: transparent;
          opacity: 1;
          color: inherit;
        }
      }
    }
  }
}

.spacer {
  flex-grow: 1;
  border-bottom: 1px solid $lightborder;
}
